import React from 'react';
import '../Styles/Services.css';

import {
    Container,
    Image,
    Row,
    Col,
  } from 'react-bootstrap';

import {
  NavLink as Link,
	withRouter
} from "react-router-dom";

import {
  BrowserView,
  MobileView
} from 'react-device-detect';

import NavBar from '../Components/NavBar';
import Footer from '../Components/Footer';

const Services = (props) => {
	console.log(props)
  return (
		<>
      <NavBar/>   

      <Container fluid className="services-page-why-work-with-us">
        <Row>
          <Col lg={6}>
          </Col>
          <Col lg={6}>
          	<div className="spwwu-box">
	          	<h1>Why <span>Work With Us</span></h1>
	          	<h4 className="tag-line pt-5 pb-3"><span>High-Quality</span> Designs</h4>
              <p>We create visually compelling online and offline designs that will surely catch people's attention. We plan and strategize your need to create the right design that will stand out and makes an impact on your audience.</p>
            </div>
          </Col>
          <MobileView className="col-sm-12 text-center overflow-hidden">    
            <Image src="/assets/images/services/img-happy-girl-design.png"/>
          </MobileView>
        </Row>
      </Container>

      <Container className="services-page-our-services">
      	<h1>Our <span>Services</span></h1>
      	<p className="pb-5">Here are the things that we can do for you:</p>
        <Row>
          <Col lg={4}>
            <div className="service-box">
              <MobileView className="text-center">
                <Image src="/assets/images/services/img-web-design-and-dev.png" className="width-66"/>
              </MobileView>
              <div className="flex">
                <BrowserView>
                  <span><Image src="/assets/images/services/img-web-design-and-dev.png" className="width-66"/></span>
                </BrowserView>
                <p className="pt-2 text-bold">Web Design and Development</p>
              </div>
              <div className="flex-right">
                <p>Impress your customers with a responsive and easy to navigate design that will catch their attention.</p>
                <p><Link to={`/get-a-q`}>CONTACT US</Link></p>
              </div>
            </div>
          </Col>
          <Col lg={4}>
            <div className="service-box">
              <MobileView className="text-center">
                <Image src="/assets/images/services/img-online-graphics.png" className="width-66"/>
              </MobileView>
              <div className="flex">
                <BrowserView>
                <span><Image src="/assets/images/services/img-online-graphics.png" className="width-66"/></span>
                </BrowserView>
            		<p className="pt-2 text-bold">Online Graphics</p>
            	</div>
              <div className="flex-right">
            		<p>Eye-catching email template designs, PDF, PowerPoint Presentations, online logos, icons, social media graphics and etc.</p>
                <p><Link to={`/get-a-q`}>CONTACT US</Link></p>
            	</div>
            </div>
          </Col>
          <Col lg={4}>
            <div className="service-box">
              <MobileView className="text-center">
                <Image src="/assets/images/services/img-offline-graphics.png" className="width-66"/>
              </MobileView>
              <div className="flex">
                <BrowserView>
                <span><Image src="/assets/images/services/img-offline-graphics.png" className="width-66"/></span>
                </BrowserView>
            		<p className="pt-2 text-bold">Offline Graphics</p>
            	</div>
              <div className="flex-right">
            		<p>Creative and high-quality design for flyers, business cards, offline logos, posters, T-shirts, invitations, etc.</p>
                <p><Link to={`/get-a-q`}>CONTACT US</Link></p>
            	</div>
            </div>
          </Col>
          <Col lg={4}>
            <div className="service-box">
              <MobileView className="text-center">
                <Image src="/assets/images/services/img-content.png" className="width-66"/>
              </MobileView>
              <div className="flex">
                <BrowserView>
                <span><Image src="/assets/images/services/img-content.png" className="width-66"/></span>
                </BrowserView>
            		<p className="pt-2 text-bold">Content Writing</p>
            	</div>
              <div className="flex-right">
            		<p>Quality content for your blog, website, social media marketing, article, etc. that will make your readers remember you.</p>
                <p><Link to={`/get-a-q`}>CONTACT US</Link></p>
            	</div>
            </div>
          </Col>
          <Col lg={4}>
            <div className="service-box">
              <MobileView className="text-center">
                <Image src="/assets/images/services/img-domain.png"/>
              </MobileView>
              <div className="flex">
                <BrowserView>
                <span><Image src="/assets/images/services/img-domain.png"/></span>
                </BrowserView>
            		<p className="pt-2 text-bold">Domain and Email</p>
            	</div>
              <div className="flex-right">
            		<p>Professional domain and email services that will make your customers remember your business and give easy access to your website.</p>
                <p><Link to={`/get-a-q`}>CONTACT US</Link></p>
            	</div>
            </div>
          </Col>
          <Col lg={4}>
            <div className="service-box">
              <MobileView className="text-center">
                <Image src="/assets/images/services/img-hosting.png"/>
              </MobileView>
              <div className="flex">
                <BrowserView>
                <span><Image src="/assets/images/services/img-hosting.png"/></span>
                </BrowserView>
            		<p className="pt-2 text-bold">Hosting</p>
            	</div>
            	<div className="flex-right">
            		<p>We make sure that we provide only the best hosting services available for your website.</p>
                <p><Link to={`/hosting`}>LEARN MORE</Link></p>
              </div>
            </div>
          </Col>
        </Row>        
      </Container>
      <Footer/>
		</>
  );
}

export default withRouter(Services);
