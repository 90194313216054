import React from 'react';
import { createBrowserHistory  } from 'history';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import ScrollToTop from './ScrollToTop.js';

import Home from './Pages/Home';
import About from './Pages/About';
import Services from './Pages/Services';
import Quotation from './Pages/Quotation';
import Hosting from './Pages/ServiceHosting';
import CancellationPolicy from './Pages/CancellationPolicy';
import PrivacyPolicy from './Pages/PrivacyPolicy';
// import HowItWorks from './Pages/HowItWorks';
// import ProjectGallery from './Pages/ProjectGallery';
// import Contact from './Pages/Contact';
// import TermsConditions from './Pages/TermsConditions';

import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";

import {
   Image
  } from 'react-bootstrap';

const history = createBrowserHistory();
class App extends React.Component {

  constructor() {
    super();
    this.state = {
      isLoading: true
    }
  }

  componentDidMount = () => {
    setTimeout(() => {
      this.setState({
        isLoading: false
      })
    }, 3000);
  }

  render() {
    const { isLoading } = this.state
    if(isLoading) {
      return (
        <div className="loader">
          <div>
            <Image src="/assets/images/preloader.gif"/>
          </div>
        </div>
      )
    }

    return (
      <>
        <Router history={history}>
          <ScrollToTop>
            <Switch>
              <Route exact path="/" component={() => <Home {...this.props}/>}/>
              <Route exact path="/about-us" component={() => <About {...this.props}/>}/>
              <Route exact path="/our-services" component={() => <Services {...this.props}/>}/>
              <Route exact path="/get-a-q" component={() => <Quotation {...this.props}/>}/>
              <Route exact path="/hosting" component={() => <Hosting {...this.props}/>}/>
              <Route exact path="/cancellation-policy" component={() => <CancellationPolicy {...this.props}/>}/>
              <Route exact path="/privacy-policy" component={() => <PrivacyPolicy {...this.props}/>}/>
              {/**
              <Route exact path="/gallery" component={() => <ProjectGallery {...this.props}/>}/>
              <Route exact path="/contact-us" component={() => <Contact {...this.props}/>}/>
              <Route exact path="/how-it-works" component={() => <HowItWorks {...this.props}/>}/>
              <Route exact path="/terms-conditions" component={() => <TermsConditions {...this.props}/>}/>
            **/}
            </Switch>
          </ScrollToTop>

        </Router>
      {/**
        <div style="
            position: fixed;
            right: 13px;
            bottom: 40px;
        "><img src="https://static-v.tawk.to/a-v3/images/bubbles/168-r-br.svg" style="
            width: 102px;
        "></div>
      **/}
      </>
    );
  }
}

export default App;
