import React from 'react';
import '../Styles/About.css';

import {
    Container,
    Image,
    Row,
    Col,
  } from 'react-bootstrap';

import NavBar from '../Components/NavBar';
import Footer from '../Components/Footer';
import {
  // NavLink as Link,
  withRouter
} from "react-router-dom";

import {
  BrowserView,
  MobileView
} from 'react-device-detect';

const About = () => {
  return (
		<>
      <NavBar/>      

      <Container fluid className="about-page-shaping-the-future">
        <Row>
          <Col md={7} sm={12} xs={12} >
          	<div className="apstf-box">
	          	<h1 className="pb-5">"Shaping the future of <span>your success</span> "</h1>
	          	<p className="pb-3"><strong>Web Stack Studio</strong> is a one-stop startup born from a passion for helping other businesses succeed. We have always been <br className="display-none-sm"/>fascinated by how powerful digital solutions are, and we do our best to incorporate this into every client experience.</p>

							<p className="pb-3">In collaborating with global businesses, we keep a watchful eye on the industry pulse and leverage cutting-edge trends <br className="display-none-sm"/>to shape your business’ future.</p>

							<p className="pb-3">As <strong>digital innovators</strong> who have spent much of our careers serving the online and offline needs of global clients, <br className="display-none-sm"/>we know the time-consuming efforts and expertise required to stand out in both the digital and offline spheres.</p>

							<p>We built our business to take that headache out of your hands with a full stack of turnkey services, from web design <br className="display-none-sm"/>and content creation to research and marketing - even clothing prints - all targeted to engage your market.</p>
							</div>
          </Col>
          <MobileView>
            <Col sm={12} xs={12} >
              <Image src="/assets/images/about/img-girl-help-splatter.png" className="img-about-page-shaping-the-future"/>
            </Col>
          </MobileView>
        </Row>
      </Container>

      <Container fluid className="about-page-our-team">
      	<h1 className="pb-5">Our <span>Team</span></h1>
      	<p className="font-weight-500"><span>Our team</span> works with dedicated industry disruptors – writers, graphic artists, web developers, IT specialists, <br className="display-none-sm"/>and digital marketers – all highly learned in innovating solutions that connect to the heart of your audience. </p>
        {/**
        <Row>
          <Col>
          	<p><Image src="/assets/images/about/img-our-team-01.png"/></p>
          </Col>
          <Col>
          	<p><Image src="/assets/images/about/img-our-team-02.png"/></p>
          </Col>
          <Col>
          	<p><Image src="/assets/images/about/img-our-team-03.png"/></p>
          </Col>
        </Row>
        **/}
      	<p className="font-weight-500"><span>Our goal</span> is to make it easier for you to succeed with comprehensive tools and services curated especially for your business.</p>
      </Container>

      <Container fluid className="about-page-we-make">
        <Row>      
          <Col lg={6}>
            <BrowserView>    
              <p className="float-right"><Image src="/assets/images/about/img-girl-using-laptop.png"/></p>
            </BrowserView>
          </Col>
          <Col lg={6}>
            <div className="apwk-box">
            	<p className="pb-3">We make the experience as hassle-free as possible too, so you can focus on other things that matter to you <br className="display-none-sm"/>and your business instead of spending countless hours trying to understand the nitty-gritty of tech <br className="display-none-sm"/>and offline methods required to succeed in your trade. The less time you spend worrying, the more <br className="display-none-sm"/>time you have to succeed.</p>
            	<p>We would love to hear about your needs. Let us get you started on the path to success. <br className="display-none-sm"/>Tell us what you need at <a className="text-white font-weight-600" href="mailto:inquiries@webstackstudio.com">inquiries@webstackstudio.com</a>, and we’d happily get back to you with a quote <br className="display-none-sm"/>in 24 to 48 hours.</p>
            </div>
          </Col>
          <MobileView className="col-sm-12 text-center">    
            <Image src="/assets/images/about/img-girl-using-laptop.png"/>
          </MobileView>
        </Row>
      </Container>

      <Footer/>
		</>
  );
}

export default withRouter(About);
