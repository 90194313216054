import React, { useEffect, useState } from 'react';
import '../Styles/ServiceHosting.css';
import apiCall from '../Providers/HttpCommon';

import {
    Container,
    Image,
    Row,
    Col,
    //OverlayTrigger,
    //Popover,
    //Button
} from 'react-bootstrap';

import {
BrowserView
} from 'react-device-detect';

import NavBar from '../Components/NavBar';
import Footer from '../Components/Footer';
import {
  NavLink as Link,
	withRouter
} from "react-router-dom";

const ServiceHosting = () => {
	const [shared, setShared] = useState([]);
	const [dedicated, setDedicated] = useState([]);
	const [plans, setPlans] = useState([]);
	const [selectedHosting, setSelectedHosting] = useState({id: null, label: null});
  
	useEffect(() => {
		_getPlansBySharedHosting();
		_getPlansByDedicatedHosting();
	}, []);
	
	const _getPlansByDedicatedHosting = async () => {		
		await apiCall.get(`linode-types/by/class/dedicated`).then((r) => {
			setDedicated(r.data);
		});
	}
	
	const _getPlansBySharedHosting = async () => {		
		await apiCall.get(`linode-types/by/class/standard`).then((r) => {
			setShared(r.data);
		});
	}
	
	const _getPlansByHosting = async (c) => {		
		await apiCall.get(`linode-types/by/class/${c}`).then((r) => {
			setPlans(r.data);
		});
	}

	const hChange = async (e) => {
		await _getPlansByHosting(e.target.value);
	}
	const priceFormat = (num) => {
		const _n = parseFloat(num);
		return '₱ ' + _n.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
	 }
	 const setHostingChange = async (e) => {
		if(e.target.value !== "0"){
			let val = e.target.value.split("|");
			await setSelectedHosting({
				id: val[0],
				label: val[1]
			});
		}else{			
			await setSelectedHosting({
				id: null,
				label: null
			});
		}
	 }
	// const popover = (
	//   <Popover id="popover-basic">
	//     <Popover.Content>
	//       We'll never share your email with <strong>anyone</strong> else.
	//     </Popover.Content>
	//   </Popover>
	// );
  return (
	<>
		<NavBar/>

		<Container fluid className="fast-and-secure-hosting">
			<Container>
				<Row>
					<Col lg={6}>
						<div className="left-side">
							<div className="left-side-text">
								<h1><span>Fast and secure web hosting</span><br/>for your business!</h1>
								<p>Not sure what to get? Let us help you choose the right plan.</p>
							</div>
						</div>
					</Col>
					<Col lg={6}>
						<div className="right-side">
							<span><Image className="d-block w-123" src="/assets/images/services/hosting/img-girl-web-hosting.png"/></span>
						</div>
					</Col>
				</Row>
			</Container>
		</Container>
		<Container className="dedicated-hosting">
			<Row>
				<Col lg={12}>
					<div className="text-center hosting-text">
						<h2 className="text-bold">Dedicated Hosting</h2>
						<p>Dedicated web hosting for your website needs.</p>
					</div>
				</Col>
			</Row>
			<Row>
				<Col lg={6}>
					<div className="left-side">
						<table className="table table-bordered table-striped">
							<thead>
								<tr>
									<th className="w-70px">RAM</th>
									<th className="w-70px">CPUs</th>
									<th className="w-125px">SSD Storage</th>
									<th className="w-70px">Price</th>
								</tr>
							</thead>
							<tbody>
								{
									dedicated.map((i, k) => {
										const _ram = i.LT_MEMORY / 1024;
										const _ssd = i.LT_DISK / 1024;
										const _cpus = i.LT_VCPUS;
										const _price = i.LT_SELLPRICE;
										return(
											<tr key={k}>
												<td>{_ram} GB</td>
												<td>{_cpus}</td>
												<td>{_ssd} GB</td>
												<td>{priceFormat(_price)}</td>
											</tr>);
									})
								}
							</tbody>
						</table>
					</div>
				</Col>
				<Col lg={6}>
					<BrowserView>	
						<div className="right-side">
							<span><Image className="d-block" src="/assets/images/services/hosting/img-dedicated-hosting.png"/></span>
						</div>	
					</BrowserView>	
				</Col>			
				<Col lg={12}>
					<div className="text-center mt-3">
						<p>Need bigger hosting services? <Link to={`/get-a-q`}>Let's talk!</Link></p>
					</div>
				</Col>
			</Row>
		</Container>
		<Container className="shared-hosting">
			<Row>
				<Col lg={12}>
					<div className="text-center hosting-text">
						<h2 className="text-bold">Shared Hosting</h2>
						<p>For multiple different websites hosted on the same server.</p>
					</div>
				</Col>
			</Row>
			<Row>
				<Col lg={6}>
					<BrowserView>	
						<div className="left-side">
							<span><Image className="d-block" src="/assets/images/services/hosting/img-shared-hosting.png"/></span>
						</div>
					</BrowserView>	
				</Col>
				<Col lg={6}>
					<div className="right-side">
						<table className="table table-bordered table-striped">
							<thead>
								<tr>
									<th className="w-70px">RAM</th>
									<th className="w-70px">CPUs</th>
									<th className="w-125px">SSD Storage</th>
									<th className="w-70px">Price</th>
								</tr>
							</thead>
							<tbody>
								{
									shared.map((i, k) => {
										const _ram = i.LT_MEMORY / 1024;
										const _ssd = i.LT_DISK / 1024;
										const _cpus = i.LT_VCPUS;
										const _price = i.LT_SELLPRICE;
										return(
											<tr key={k}>
												<td>{_ram} GB</td>
												<td>{_cpus}</td>
												<td>{_ssd} GB</td>
												<td>{priceFormat(_price)}</td>
											</tr>);
									})
								}
							</tbody>
						</table>
					</div>
				</Col>
			</Row>
		</Container>
		<Container fluid className="ready-to-get-started text-center">
			<Container fluid  className="ready-to-get-started-fluid">
				<Container className="ready-to-get-started-box">
				<Row>
					<Col lg={12}>
						<div className="ready-to-get-text">
							<h1>Ready to get started?</h1>
							<h5 className="text-bold">Choose your plan:</h5>
						</div>
					</Col>
					<Col lg={12} className="text-center">
						<div className="choose-your-plan">							
							<Row>
								<Col lg={3}>
									<div>
										<h3>Hosting:</h3>
										<select className="form-control" onChange={hChange}>
											<option value="">Select your hosting</option>
											<option value="dedicated">Dedicated Hosting</option>
											<option value="standard">Shared Hosting</option>
										</select>
									</div>
								</Col>
								<Col lg={3}>
									<div>
										<h3>Plan:</h3>
										<select className="form-control" onChange={setHostingChange}>
											<option value="0">Select your plan</option>
											{
												plans.map((i, k) => {
													return(<option value={`${i.LT_LABEL}|${i.LT_LABEL.replace('Dedicated', '').replace('Shared', '')}`} key={k}>{i.LT_LABEL.replace('Dedicated', '').replace('Shared', '')} - {priceFormat(i.LT_SELLPRICE)}</option>);
												})
											}
										</select>
									</div>
								</Col>
								<Col lg={3}>
									<div>
										<h3>&nbsp;</h3>
										<p><Link className="btn btn-success btn-get-it" to={`/get-a-q${(selectedHosting.id !== null)? `?refPlanId=${selectedHosting.id}&refPlanLabel=${selectedHosting.label}` : ``}`}>GET IT!</Link></p>
									</div>
								</Col>
							</Row>
						</div>
					</Col>
					<Col lg={12}>
						<div className="let-us-know">
							<p>Need help or can't find what you're looking for? Let us know!</p>
							<p><Link className="btn btn-let-us-know" to={`/get-a-q`}>Let us know!</Link></p>
						</div>
					</Col>
				</Row>
				</Container>
			</Container>
		</Container>
		<Container fluid className="what-is-web-hosting text-center">
		<Row>
			<Col lg={12}>
				<div>
					<h2>What is <span>Web Hosting?</span></h2>
					<p>Web hosting is an online service that provides storage for the files that make up your website.<br/> All of the files (texts, images, databases, videos, documents, etc.) that make up your<br/> website takes up space and requires storage.</p>
					<p>Web hosting enables your website to be accessible on the internet.</p>
				</div>
			</Col>
		</Row>
		</Container>

		<Footer/>
	</>
  );
}

export default withRouter(ServiceHosting);
