import React from 'react';
import '../Styles/CancellationPolicy.css';

import {
  Container,
  Image,
  Row,
  Col,
  Tabs,
  Tab
} from 'react-bootstrap';

// import {
// BrowserView,
// MobileView
// } from 'react-device-detect';

import NavBar from '../Components/NavBar';
import Footer from '../Components/Footer';

import {
	withRouter
} from "react-router-dom";

const CancellationPolicy = () => {
  return (
		<>
      <NavBar/>
      <Container fluid className="cancellation-policy">
        <Container>
          <Row>
            <Col lg={6}>
              <div className="left-side">
                <div className="left-side-text">
                  <h1>Cancellation Policy</h1>
                  <p>Customer satisfaction is very important to us. We understand that sometimes, there are circumstances that may affect the progress of the project. If you wish to cancel your projects or subscriptions, please check out our cancellation policy for the service/s below.</p>
                </div>
              </div>
            </Col>
            <Col lg={6}>
              <div className="right-side">
                <span><Image className="d-block w-123" src="/assets/images/cancellation-policy/img-girl-web-hosting.png"/></span>
              </div>
            </Col>
          </Row>
        </Container>
      </Container>

      <Container className="cancellation-policy-tabs">
        <Row>
          <Col>                  
            <Tabs
              defaultActiveKey="first"
              className="mb-3"
              fill
            >
              <Tab eventKey="first" title="Hosting, Domain and Email">
                <p>Cancellations may only be requested until 5 days prior to the next billing. Cancellations made after the said days will not be refunded.</p>
              </Tab>
              <Tab eventKey="second" title="Web and Software Development">
                <p className="sub-title mb-1">One-Time Payment</p>
                <p className="mb-5">Termination of work on Web and Software Development may be requested. Refunds will be determined by how much work has already been completed. A refund will no longer be allowed once coding has begun.</p>

                <p className="sub-title mb-1">Installment Plan</p>
                <p>Termination of work on the instalment plan may be requested regardless of the stage being worked on. Refunds will be determined by how much work has already been completed on the stage where the cancellation was requested. However, once the coding stage has started, refunds will no longer be available.
                </p>
              </Tab>
              <Tab eventKey="third" title="Graphic Design">
                <p>Cessation of work on Graphic Design services may be requested. Refunds are only available before the start of the design. Once the samples of the design have been completed, whether they have been approved or not, refunds will no longer be available.</p>
              </Tab>
              <Tab eventKey="fourth" title="Content Writing and Video Editing">
                <p>Termination of work requested before the start of writing will receive a 75% refund. Once writing has started, a refund will no longer be allowed.</p>
              </Tab>
            </Tabs>
          </Col>
        </Row>
      </Container>
      <Footer/>
		</>
  );
}

export default withRouter(CancellationPolicy);
