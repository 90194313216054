import React from 'react';
import '../Styles/Quotation.css';

import {
    Container,
    Image,
    Row,
    Col,
    //OverlayTrigger,
    //Popover,
    //Button
  } from 'react-bootstrap';

import NavBar from '../Components/NavBar';
import Footer from '../Components/Footer';
import {
  // NavLink as Link,
	withRouter
} from "react-router-dom";

const Quotation = () => {
	// const popover = (
	//   <Popover id="popover-basic">
	//     <Popover.Content>
	//       We'll never share your email with <strong>anyone</strong> else.
	//     </Popover.Content>
	//   </Popover>
	// );
  return (
		<>
      <NavBar/>
      <Container className="quotation-page">
        <Row>
          <Col lg={6}>
	          <div>
	          	<h2>Let's Talk!</h2>
	          	<p>Got questions or a project? We'd love to hear from you! Just send us a quick message and let us bring your ideas to life!</p>
	          </div>
	          <div>
	          	<p>You can reach out to us through email at <br/> <a href="mailto:inquiries@webstackstudio.com">inquiries@webstackstudio.com</a> </p>
	          </div>
	          <div>
	          	<h4>Or</h4>
	          	<p>Send us a message on our Facebook page</p>
	          </div>
	          <div>
	          	<a href="https://www.facebook.com/WebStackStudio" target="_blank" rel="noopener noreferrer"><Image src="/assets/images/fb.jpg" className="width-35"/></a>
	          </div>
          </Col>
          <Col lg={6}>
	          <div>
	          	<h2>Privacy Protection</h2>
	          	<p>We value your privacy. We will not use or share your information with anyone else. We make sure that data are password-protected and kept in a secure place where no one else can access.</p>
	          </div>
          </Col>
          {/**
          <Col lg={6}>
          	<div>

			        <Row>
			          <Col lg={6}>
								  <div className="form-group">
								    <label>Name</label>
								    <input type="text" className="form-control" placeholder="Enter your name "/>
								  </div>
          			</Col>
			          <Col lg={6}>
								  <div className="form-group">
									  <OverlayTrigger trigger="click" placement="top" overlay={popover}>
								    <label>Email address</label>
									  </OverlayTrigger>
								    <input type="text" className="form-control" placeholder="Enter your email "/>
								  </div>
          			</Col>
			          <Col lg={12}>
								  <div className="form-group">
								    <label>Project description</label>
								    <textarea className="form-control" rows={5} placeholder="Write your Project description"></textarea>
								  </div>
          			</Col>

        			</Row>
                                
							<div className="form-group">
								<div className="g-recaptcha" data-sitekey="6LfsepkUAAAAAMmjhluggvVk40wIrV_OCUDFhpMq"></div>
							</div>
		      		<Button variant="primary" size="lg" block>Submit</Button>

						</div>
          </Col>
        **/}
        </Row>
      </Container>

      <Footer/>
		</>
  );
}

export default withRouter(Quotation);
