import React from 'react';
import '../Styles/PrivacyPolicy.css';

import {
  Container,
  Image,
  Row,
  Col
} from 'react-bootstrap';

// import {
// BrowserView,
// MobileView
// } from 'react-device-detect';

import NavBar from '../Components/NavBar';
import Footer from '../Components/Footer';

import {
	withRouter
} from "react-router-dom";

const PrivacyPolicy = () => {
  return (
		<>
      <NavBar/>
      <Container fluid className="privacy-policy">
        <Container>
          <Row>
            <Col lg={6}>
              <div className="right-side">
                <span><Image className="d-block" src="/assets/images/privacy-policy/img-lock.png"/></span>
              </div>
            </Col>
            <Col lg={6}>
              <div className="right-side">
                <div className="right-side-text">
                  <h1>Privacy Policy</h1>
                  <p>We value your privacy. For this reason, we only gather and use personal information as necessary to deliver our products and services as well as to communicate with you about the matter. These information may include, but not limited to, name, email, location, IP address, and social accounts.</p>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </Container>
      <Container className="privacy-policy-content">
        <Row>
          <Col>
            <p className="sub-title mb-1">Collection and Use of Personal Information</p>
            <p>When you visit our website, we may collect information from you in various ways, including but not limited to, when you make a purchase, fill up a form, request assistance or when you simply visit the website. We will also gather information from you when you decide to use one of our services. These information will be used to:</p>
            
            <p className="mb-1">•Improve customer service - your information will help us effectively provide the products and services better;</p>
            <p className="mb-1">•Personalize experience - information collected from you will help us better understand your individual needs;</p>
            <p className="mb-1">•Improve our website - we are constantly working to improve our products and services based on the feedback we receive from you</p>
            <p className="mb-1">•To send promotion, survey or newsletter - only when you agree, we will deliver information about our products and services so you'll learn more about them</p>
            <p className="mb-5">•To send periodic emails - we will use the email address you provided to send updates about the products or services you have obtained from us, respond to your inquiries or other requests</p>
            
            <p className="sub-title mb-1">How We Protect Your Information</p>
            <p className="mb-5"> We utilize appropriate data collection, storage, and processing policies and security measures to protect your personal information, transaction information and data stored on our site from unauthorized access, alteration, disclosure, or destruction.</p>
            <p className="sub-title mb-1">Sharing Your Personal Information</p>

            <p className="mb-5">We do not sell, trade, or rent your personal identification information to others. We do not sell, trade, or rent your personal identification information to others. This does not include trusted third parties who help us operate our website, manage our business, or provide services to you, as long as those parties agree to keep your information private.</p>
            <p className="sub-title mb-1">Third Parties</p>

            <p className="mb-5">We may disclose your information to third parties that we use to help us operate our business. These third parties may provide content and links that may change constantly. Because these third parties have their own privacy policies, browsing and interaction with them is subject to their own terms and conditions.
            </p>
            <p className="sub-title mb-1">Your Consent</p>
            <p className="mb-5">By using our site, you express your consent to our policy. </p>
          </Col>
        </Row>
      </Container>
      <Footer/>
		</>
  );
}

export default withRouter(PrivacyPolicy);

