import React from 'react';
// import './Home.css';

import {
    Carousel,
    Container,
    Image,
    Row,
    Col,
  } from 'react-bootstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
    faBookmark,
    faClock,
    faDesktop,
    faStar,
    faPlay
  } from '@fortawesome/free-solid-svg-icons';

import {
  NavLink as Link,
  withRouter
} from "react-router-dom";

import {
  BrowserView
} from 'react-device-detect';

import NavBar from '../Components/NavBar';
import Footer from '../Components/Footer';

const wrapper = React.createRef();

const Home = () => {
  return (
		<>
    <BrowserView>
      <Carousel ref={wrapper} interval={5000} className="home-carousel">
        {/**
          <Carousel.Item>
            <img
              className="d-block w-100"
              src="/assets/images/slider/img-index.jpg"
              alt="First slide"
            />
            <Carousel.Caption>
              <h1 className="font-weight-bold">We are Web Stack Studio</h1>
            </Carousel.Caption>
          </Carousel.Item>
        **/}
          <Carousel.Item>
            <img
              className="d-block w-100 mb-less-45"
              src="/assets/images/slider/img-index-graphic-design.jpg"
              alt="Third slide"
            />
            <Carousel.Caption>
              <h1 className="font-weight-bold">Graphic <span className="text-yellow">Design</span> Solution</h1>
              <p>We deliver high-quality and visually compelling designs <br className="display-none-sm"/>that will showcase your business and attract customers.</p>
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item>
            <img
              className="d-block w-100 mb-less-45"
              src="/assets/images/slider/img-index-web-design.jpg"
              alt="Third slide"
            />

            <Carousel.Caption>
              <h1 className="font-weight-bold"><span className="text-green">Web Services</span></h1>
              <p className="text-black">We make sure that your website makes a good impression, <br className="display-none-sm"/>shows professionalism and showcase your business.</p>
            </Carousel.Caption>
          </Carousel.Item>
        </Carousel>
      </BrowserView>      

      <NavBar/>

      <Container fluid className="grow-your-business bg-white">
        <Row>
          <Col md={6} sm={12} xs={12}><Image src="/assets/images/home/img-grow-your-business.png" className="float-right p-0 img-grow-your-business"/></Col>
          <Col md={6} sm={12} xs={12}>
            <div className="grow-your-business-right-box">
              <h1 className="pt-4 text-grow-your-business">Grow your business <br className="display-none-sm"/><span className="font-weight-bold text-skyblue">without the hassle</span></h1>
              <p>Whether it is a website or a graphic design, save yourself from the <br className="display-none-sm"/>hassle and let us do the hard work. We design and develop stunning <br className="display-none-sm"/>online and offline graphic designs, responsive websites and mobile <br className="display-none-sm"/>applications.</p>
              <p>Our goal is to work efficiently to provide you with the best to help your <br className="display-none-sm"/>business succeed.</p>
              <p><FontAwesomeIcon icon={faBookmark} className="text-skyblue w-15-i mr-3" /> Look professional</p>
              <p><FontAwesomeIcon icon={faClock} className="text-skyblue w-15-i mr-3" /> Save your time and let us handle it</p>
              <p><FontAwesomeIcon icon={faDesktop} className="text-skyblue w-15-i mr-3" /> Amazing designs</p>
              <p><FontAwesomeIcon icon={faStar} className="text-skyblue w-15-i mr-3" /> Premium services</p>
            </div>
          </Col>
        </Row>
      </Container>

      <Container fluid className="featured-services text-center ">
        <Container className="text-white position-relative">
          <h1>Featured Services</h1>
          <Row className="pt-5 line-bg">
            <Col md={4} sm={12} xs={12} className="col-feature">
              <Image src="/assets/images/home/img-01-feature.png"/>
              <h4 className="pt-4">Web Development</h4>
              <p className="pb-4">We make sure that your website makes a good impression, shows professionalism and showcases your business.</p>
              <Link to={`/get-a-q`} className="learn-more"><span>get a quote</span> <FontAwesomeIcon icon={faPlay} size="xs" /></Link>
            </Col>
            <Col md={4} sm={12} xs={12} className="col-feature">
              <Image src="/assets/images/home/img-02-feature.png"/>
              <h4 className="pt-4">Graphic Design</h4>
              <p className="pb-4">We deliver high-quality and visually compelling designs that will showcase your business and attract customers.</p>
              <Link to={`/get-a-q`} className="learn-more"><span>get a quote</span> <FontAwesomeIcon icon={faPlay} size="xs" /></Link>
            </Col>
            <Col md={4} sm={12} xs={12} className="col-feature">
              <Image src="/assets/images/home/img-03-feature.png"/>
              <h4 className="pt-4">Content</h4>
              <p className="pb-4">Quality content for your blog, website, social media marketing, article, etc., that will make your readers remember you.</p>
              <Link to={`/get-a-q`} className="learn-more"><span>get a quote</span> <FontAwesomeIcon icon={faPlay} size="xs" /></Link>
            </Col>
          </Row>
        </Container>
      </Container>

      <Container fluid className="how-it-works">
        <Container >
          <h1 className="text-center">How it <span className="text-skyblue font-weight-bold">works?</span></h1>
          <Row className="pt-5">
            <Col md={6} sm={12} xs={12} className="p-0"><Image src="/assets/images/home/img-how-it-works-number-01-pic.png" className="thumbnail"/></Col>
            <Col md={6} sm={12} xs={12} className="p-0">
              <div className="img-number">
                <Image src="/assets/images/home/img-how-it-works-number-01.png" className="mr-3"/> <h2 className="d-inline-block">Consultation & <span className="text-skyblue">Planning</span></h2>
              </div>
              <p className="pt-4">We'll start off by planning and discussing what you need <br className="display-none-sm"/>and when you need it. This includes gathering all the details and things <br className="display-none-sm"/>needed to deliver the best project for you.</p>
            </Col>
          </Row>
          <Row>
            <Col md={6} sm={12} xs={12} className="p-0 display-none-lg"><Image src="/assets/images/home/img-how-it-works-number-02-pic.png" className="thumbnail"/></Col>
            <Col md={6} sm={12} xs={12} className="p-0">
              <div className="img-number">
                <Image src="/assets/images/home/img-how-it-works-number-02.png" className="mr-3"/> <h2 className="d-inline-block text-skyblue ">Processing</h2>
              </div>
              <p className="pt-4">Once we get all the necessary information and materials, we will <br className="display-none-sm"/>begin working on the project. We'll also send you updates on <br className="display-none-sm"/>where we are and what's left to be done.</p>
            </Col>
            <Col md={6} sm={12} xs={12} className="p-0 display-none-sm"><Image src="/assets/images/home/img-how-it-works-number-02-pic.png" className="thumbnail"/></Col>

          </Row>
          <Row>
            <Col md={6} sm={12} xs={12} className="p-0"><Image src="/assets/images/home/img-how-it-works-number-03-pic.png" className="thumbnail"/></Col>
            <Col md={6} sm={12} xs={12} className="p-0">
              <div className="img-number">
                <Image src="/assets/images/home/img-how-it-works-number-03.png" className="mr-3"/> <h2 className="d-inline-block">Project <span className="text-skyblue">Delivery</span></h2>
              </div>
              <p className="pt-4">Before delivery, we'll make sure that it meets all the criteria for a <br className="display-none-sm"/>project to be labelled as complete. This includes detailed checking <br className="display-none-sm"/>and testing to make sure everything is working and how you want it to be.</p>
            </Col>
          </Row>
        </Container>
      </Container>

      <Footer/>
		</>
  );
}

export default withRouter(Home);
