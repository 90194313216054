import React from 'react';
// import './NavBar.css';
import {
    Navbar,
    Nav,
    Container,
    Image,
  } from 'react-bootstrap';

import {
  NavLink as Link,
	withRouter
} from "react-router-dom";

const NavBar = () => {
  return (
		<>
	    <Navbar expand="lg" sticky="top" className="bg-color-f5f5f5-i ">
	      <Container>
	        <Navbar.Brand href="/"><Image src="/assets/images/logo.png"/></Navbar.Brand>
	        <Navbar.Toggle aria-controls="navbar-nav-header" />
	        <Navbar.Collapse id="navbar-nav-header" className="mt-2">
	          <Nav className="ml-auto">

	            <Link className="nav-link" activeClassName="active" exact to="/">Home</Link>
	            <Link className="nav-link" activeClassName="active" exact to="/about-us">About Us</Link>
	            <Link className="nav-link" activeClassName="active" exact to="/our-services">Our Services</Link>
	            {/**<Link className="nav-link" activeClassName="active" exact to="/gallery">Gallery</Link>
	            <Link className="nav-link" activeClassName="active" exact to="/contact-us">Contact Us</Link>**/}
	            <Link className="nav-link" activeClassName="active" exact to="/get-a-q"><span className="bg-warning btn-get-a-q">quote</span></Link>

	          </Nav>

	        </Navbar.Collapse>
	      </Container>
	    </Navbar>
		</>
  );
}

export default withRouter(NavBar);
