import React from 'react';
// import './Footer.css';
import {
    //Navbar,
    //Nav,
    Image,
    Container,
  } from 'react-bootstrap';

import {
  NavLink as Link,
	withRouter
} from "react-router-dom";

const Footer = () => {
  return (
		<>
		<Container fluid className="text-center footer-strip">
			<p className="mb-0"><Image src="/assets/images/logo-footer.png"/></p>
			<p className="mb-0 pt-4 pb-4"><span className="font-weight-bold text-green">Web</span> <span className="font-weight-bold text-skyblue">Stack</span> <span>Studio</span> is a one-stop startup born from a passion for helping other businesses succeed.</p>
		</Container>
		
		<Container fluid className="text-center footer-strip bg-dark text-white">
			<p className="mb-0 pt-3 font-weight-bold">Copyright 2020 Web Stack Studio</p>
			<p className="pb-3 mb-0 text-14"><Link className="text-white" exact to="/privacy-policy">Privacy Policy</Link> | <Link className="text-white" exact to="/cancellation-policy">Cancellation Policy</Link></p>
		</Container>

		    {/**
	    <Navbar bg="dark" variant="dark" expand="lg" sticky="bottom">
	      <Container >
	        <Navbar.Brand className="text-center" href="/"><small>Copyright 2020 Web Stack Studio</small></Navbar.Brand>

	        <Navbar.Toggle aria-controls="navbar-nav-footer" />
	        <Navbar.Collapse id="navbar-nav-footer" className="mt-0">
	          <Nav className="ml-auto">

	            <Link className="nav-link" exact to="/terms">Terms and Conditions</Link>
	            <Link className="nav-link" exact to="/privacy-policy">PrivacyPolicy</Link>
	            <Link className="nav-link" exact to="/about-us">About Us</Link>
	            <Link className="nav-link" exact to="/our-services">Services</Link>
	            <Link className="nav-link" exact to="/how-it-works">How it Works</Link>

	          </Nav>

	        </Navbar.Collapse>
	      </Container>
	    </Navbar>
			**/}
		</>
  );
}

export default withRouter(Footer);
